import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Image from "gatsby-image"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GoTriangleLeft } from "react-icons/go"
import SEO from "../components/SEO"

export const query = graphql`
  query($slug: String!) {
    note: mdx(
      frontmatter: { slug: { eq: $slug }, type: { eq: "BOOK_NOTES" } }
      fields: { show: { eq: true } }
    ) {
      frontmatter {
        title
        last_updated_on(formatString: "MMM DD, YYYY")
        authors
        tags
        slug
        cover {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      body
    }
  }
`

const noteTemplate = ({ data, pageContext }) => {
  const { title, last_updated_on, authors, cover } = data.note.frontmatter
  const { body } = data.note
  return (
    <Layout>
      <SEO title={title} />
      <Wrapper>
        <AniLink fade className="breadcrumb" to="/notes">
          <GoTriangleLeft />
          <span> All Book Notes </span>
        </AniLink>
        <div className="book-info">
          <div className="book-img">
            <Image
              fluid={cover.childImageSharp.fluid}
              alt={title}
              className="img"
            />
          </div>
          <div className="book-details">
            <h1>{title}</h1>
            <h3>by {authors}</h3>
          </div>
        </div>
        <div className="book-body">
          <div className="last-tended-on">
            <span>Last tended on {last_updated_on}</span>
          </div>
          <hr />
          <MDXRenderer>{body}</MDXRenderer>
          <hr />
        </div>
        <AniLink fade className="breadcrumb" to="/">
          <GoTriangleLeft />
          <span> All Book Notes </span>
        </AniLink>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .breadcrumb {
    margin: 2em 0 2em 2em;
    list-style-type: none;
    transition: var(--mainTransition);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }
  .book-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 2em;
    justify-content: center;
  }
  .book-img {
    margin: 0 2em;
  }
  .book-details {
    display: none;
  }
  .book-body {
    padding: 0 10%;
    text-align: left;
    font-size: 1.04em;
    margin: 1em auto 0px;
    align-items: flex-start;
    margin: 0 auto;

    h3 {
      text-align: center;
    }

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }
  }
  .last-tended-on {
    display: flex;
    justify-content: center;

    span {
      color: gray;
      font-weight: 1rem;
      padding-bottom: 12px;
    }
  }

  @media screen and (min-width: 700px) {
    .book-img {
      min-width: 25%;
      max-width: 50%;
    }
  }
  @media screen and (max-width: 700px) {
    .link {
      padding: 1em 1em;
    }
    .book-info {
      flex-direction: column;
    }
    .book-img {
      width: 200px;
      margin: auto;
    }
    .book-details {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .book-body {
      padding: 0 1em;
    }
  }
`

export default noteTemplate
